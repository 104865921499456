<!--
 * @Author: huadan
 * @Date: 2021-04-09 10:05:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 11:41:19
 * @Description: 修改密码
-->
<style lang="less" scoped>
.modify-password {
  display: flex;
  flex-direction: column;

  .title {
    font-size: @font_size_6;
    font-weight: bold;
    margin: 30px 0;
  }
}
</style>

<template>
  <NeoPageLayout class="modify-password">
    <a-row class="title" type="flex" justify="center">
      <a-col :span="8">{{ $t('modifyPassword.title') }}</a-col>
    </a-row>
    <!--  修改密码 -->
    <a-form-model ref="form" :labelCol="labelCol" :wrapperCol="wrapperCol" :model="form" :rules="rules">
      <!--  原密码 -->
      <a-form-model-item prop="password" :label="$t('modifyPassword.old_password')">
        <a-input
          v-model="form.password"
          type="password"
          autocomplete="off"
          :placeholder="$t('modifyPassword.confirm_old_password')"
        />
      </a-form-model-item>
      <!--  新密码 -->
      <a-form-model-item prop="newPassword" :label="$t('modifyPassword.new_password')">
        <a-input
          v-model="form.newPassword"
          type="password"
          autocomplete="off"
          :placeholder="$t('modifyPassword.confirm_new_password')"
        />
      </a-form-model-item>
      <!--  确认密码 -->
      <a-form-model-item prop="confirmPassword" :label="$t('modifyPassword.confirm_password')">
        <a-input
          v-model="form.confirmPassword"
          type="password"
          autocomplete="off"
          :placeholder="$t('modifyPassword.confirm_new_password2')"
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 8, offset: 9 }">
        <a-button type="primary" @click="onSubmit">{{ $t('modifyPassword.confirm_modify') }}</a-button>
      </a-form-model-item>
    </a-form-model>
  </NeoPageLayout>
</template>
<script>
import { Icon } from 'ant-design-vue'
import md5 from 'js-md5'

const { validate } = window.$g

export default {
  name: 'modifyPassword',
  data() {
    const checkConfirm = (rule_, value, callback) => {
      const password = this.form.newPassword
      if (value && password && value !== password) {
        callback(this.$t('modifyPassword.check_confirm'))
      } else {
        callback()
      }
    }

    return {
      labelCol: { span: 9 },
      wrapperCol: { span: 6 },
      form: {
        password: '',
        newPassword: '',
        confirmPassword: '',
      },
      //规则
      rules: {
        password: [validate.min_string_6],
        newPassword: [validate.min_string_6],
        confirmPassword: [validate.min_string_6, { validator: checkConfirm }],
      },
    }
  },
  methods: {
    //提交
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            await this.$http('password', {
              password: md5(this.form.password),
              newPassword: md5(this.form.newPassword),
            })
            this.loading = false
            // 重置表单
            this.$refs.form.resetFields()
            this.$success({
              title: this.$t('modifyPassword.modify_success'),
              icon: (h) => h(Icon, { props: { type: 'check-circle', theme: 'filled' } }),
              content: this.$t('modifyPassword.pw_modify_success'),
              okText: this.$t('confirm'),
              onOk: () => {
                if (this.$store.state.app.userInfo.superTenant === 1) {
                  this.$router.push({ name: 'employee' })
                } else {
                  this.$router.push({ name: 'home' })
                }
              },
            })
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        }
      })
    },
  },
}
</script>
